export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/(authenticated)/admin/geocode": [5,[2]],
		"/auth/login": [~15],
		"/(authenticated)/onderkomenbeheer": [6,[2,3]],
		"/(authenticated)/onderkomenbeheer/boekingen": [7,[2,3]],
		"/(authenticated)/onderkomenbeheer/boekingen/[uuid]": [~8,[2,3]],
		"/(authenticated)/onderkomenbeheer/kamers/[...uid]": [9,[2,3]],
		"/(authenticated)/onderkomenbeheer/onderkomenplaatsingen": [10,[2,3]],
		"/(authenticated)/onderkomenbeheer/onderkomenplaatsingen/[uuid]": [~11,[2,3]],
		"/(authenticated)/onderkomenbeheer/onderkomens": [12,[2,3]],
		"/(authenticated)/onderkomenbeheer/onderkomens/[uuid]": [~13,[2,3]],
		"/(authenticated)/onderkomenbeheer/zoeken": [~14,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';